'use client'
import * as React from 'react'
import type { SVGProps } from 'react'

const SvgLayerSparkLogo = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 32 32" {...props}>
        <path
            className="fill-primary dark:fill-primary-400"
            d="M16.38,1.76c-.05.1-.08.16-.12.22-.74,1.06-.63,2.12.04,3.18.66,1.04,1.69,1.94,2.7,2.85,1.07.96,2.13,1.92,2.9,2.99,1.91,2.64,1.52,5.17-1.05,7.57-.77.72-1.62,1.39-2.37,2.12-1.94,1.89-2.29,3.9-1.06,6.01.02.03.03.06.06.09.12.17.1.32-.13.45-.23.13-.49.09-.7,0-.83-.37-1.74-.7-2.46-1.14-2.18-1.35-2.97-3.01-2.64-4.89.19-1.06.85-2,1.72-2.9.94-.98,1.92-1.94,2.57-3.01.81-1.34.98-2.72.52-4.11-.31-.93-.76-1.85-1.22-2.76-.49-.97-1.04-1.93-1.16-2.95-.15-1.18.15-2.32,1.04-3.38.14-.17.33-.34.55-.48.1-.06.35-.08.5-.06.13.02.22.14.31.21Z"
        />
        <path
            className="fill-secondary dark:fill-secondary-400"
            d="M15.34,30.82c-3.31-.01-6.12-1.19-8.35-3.54-2.08-2.2-3-4.79-2.45-7.77.33-1.78,1.27-3.28,2.65-4.48.86-.75,1.81-1.39,2.72-2.09,1-.76,1.92-1.59,2.48-2.72.19-.4.3-.84.44-1.26.01-.04.01-.08.02-.12.06-.24.2-.4.47-.37.27.03.34.22.34.45,0,1.07-.23,2.1-.68,3.08-.52,1.13-1.25,2.13-1.99,3.13-.81,1.1-1.57,2.23-2,3.52-.88,2.65-.38,5.1,1.24,7.35,1.52,2.11,3.63,3.36,6.25,3.83.38.07.57.22.56.48,0,.29-.24.47-.62.49-.36.01-.72,0-1.08,0Z"
        />
        <path
            className="fill-secondary dark:fill-secondary-400"
            d="M27.62,20.61c-.03,2.05-.78,4.09-2.21,5.9-.86,1.09-1.92,1.97-3.11,2.71-.45.28-.69.21-.94-.25-1.01-1.8-1-3.64-.08-5.44.4-.79,1.03-1.47,1.55-2.2.5-.7,1.06-1.37,1.48-2.11.83-1.47.98-3.02.36-4.61-.08-.2-.17-.4-.24-.61-.07-.18-.06-.36.13-.47.2-.11.39-.05.52.11.23.28.46.56.66.86,1.18,1.79,1.87,3.73,1.88,6.09Z"
        />
        <path
            className="fill-primary dark:fill-primary-400"
            d="M20.36,4.42c.09-.07.21-.21.36-.26.16-.06.35-.05.52-.04,1.49.07,2.36-.98,2.41-2.29,0-.14,0-.28,0-.41.02-.23.13-.41.39-.41.28,0,.35.18.37.41.05.43.06.88.19,1.28.29.86.93,1.35,1.89,1.41.23.01.45.02.68.04.24.02.44.12.44.37,0,.25-.21.35-.44.37-.21.02-.43.02-.65.02-1.34.04-2.12,1.24-2.1,2.22,0,.12,0,.24-.01.36-.02.23-.13.4-.39.41-.23.01-.38-.17-.38-.44,0-.47-.03-.92-.26-1.35-.41-.76-1.05-1.17-1.94-1.2-.24,0-.47-.01-.71-.02-.23-.01-.36-.13-.38-.45Z"
        />
        <path
            className="fill-primary dark:fill-primary-400"
            d="M9.67,8.11s-.08.15-.16.19c-.13.07-.29.1-.44.12-.75.08-1.18.49-1.26,1.22-.01.12,0,.25-.05.35-.07.11-.2.26-.31.26-.11,0-.26-.13-.33-.24-.06-.1-.04-.25-.06-.38-.06-.7-.53-1.14-1.25-1.2-.12-.01-.26,0-.36-.05-.12-.07-.28-.2-.28-.3,0-.12.13-.27.24-.36.08-.06.23-.06.36-.06.63-.04,1.05-.36,1.22-.95.06-.21.08-.43.12-.64.04-.19.16-.37.37-.31.13.03.23.21.3.34.06.11.03.25.06.38.14.69.58,1.19,1.35,1.18.31,0,.48.16.48.45Z"
        />
    </svg>
)
export default SvgLayerSparkLogo
