import { enUS } from 'date-fns/esm/locale'
import { AiOutlineHome } from 'react-icons/ai'
import { HiOutlineBookOpen, HiQueueList } from 'react-icons/hi2'
import { MdOutlineQuestionAnswer, MdPerson } from 'react-icons/md'
import { BsFillGearFill, BsGear } from 'react-icons/bs'

export type MenuItem = {
    to: string
    label: string
}

const siteMetadata = {
    title: 'LayerSpark - Ignite your career',
    author: 'Brett Hale',
    headerTitle: 'LayerSpark',
    description: 'AI for your career',
    language: 'en-us',
    theme: 'system', // system, dark or light
    siteUrl: `https://${process.env.NEXT_PUBLIC_VERCEL_URL}/` ?? 'http://localhost:3000/',
    salesMenuEnabled: false,
    locale: enUS,
    salesMenuItems: [
        // { to: '/pricing#feature-table', label: 'Features' },
        // { to: '/pricing', label: 'Pricing' },
        // {to: "/contact", label: "Contact"},
    ],
    asideLinks: [
        {
            slug: '/app/',
            icon: AiOutlineHome,
            title: 'Dashboard',
        },
        {
            slug: '/app/experiences/',
            icon: HiQueueList,
            title: 'Experience',
        },
        {
            slug: '/app/brag-book/',
            icon: HiOutlineBookOpen,
            title: 'Brag Book',
        },
        {
            slug: '/app/interviews/',
            icon: MdOutlineQuestionAnswer,
            title: 'AI Interviews',
            badge: 'Pro',
            active: false,
        },
        {
            slug: '/app/career-profile/',
            icon: MdPerson,
            title: 'Career Profile',
        },
        // {
        //   slug: "/app/settings/",
        //   icon: BsGear,
        //   title: "Settings"
        // },
    ],
    // todo: eventually get these from Stripe
    stripe_products: ['prod_PGeZRRmd5K5nuh', 'prod_P8gjdqvEDdEKAa', 'prod_P8gjw4iYtuL5iq'],
    products: {
        free: {
            label: 'Always Free',
            price: '129',
            features: ['storage.resume', 'storage.coverLetter', 'storage.brags'],
            active: true,
        },
        advanced: {
            label: 'Always Free',
            price: '129',
            features: ['storage.resume', 'storage.coverLetter', 'storage.brags'],
            active: true,
        },
        premo: {},
        founder: {
            label: 'Always Free',
            price: '129',
            features: ['storage.resume', 'storage.coverLetter', 'storage.brags'],
            active: true,
        },
        lifetime: {},
    },
    // analytics: {
    // If you want to use an analytics provider you have to add it to the
    // content security policy in the `next.config.js` file.
    // supports Plausible, Simple Analytics, Umami, Posthog or Google Analytics.
    // umamiAnalytics: {
    //   // We use an env variable for this site to avoid other users cloning our analytics ID
    //   umamiWebsiteId: process.env.NEXT_UMAMI_ID, // e.g. 123e4567-e89b-12d3-a456-426614174000
    // },
    // plausibleAnalytics: {
    //   plausibleDataDomain: '', // e.g. tailwind-nextjs-starter-blog.vercel.app
    // },
    // simpleAnalytics: {},
    // posthogAnalytics: {
    //   posthogProjectApiKey: '', // e.g. 123e4567-e89b-12d3-a456-426614174000
    // },
    // googleAnalytics: {
    //   googleAnalyticsId: '', // e.g. G-XXXXXXX
    // },
    // },
    // comments: {
    //   // If you want to use an analytics provider you have to add it to the
    //   // content security policy in the `next.config.js` file.
    //   // Select a provider and use the environment variables associated to it
    //   // https://vercel.com/docs/environment-variables
    //   provider: "giscus", // supported providers: giscus, utterances, disqus
    //   giscusConfig: {
    //     // Visit the link below, and follow the steps in the 'configuration' section
    //     // https://giscus.app/
    //     repo: process.env.NEXT_PUBLIC_GISCUS_REPO,
    //     repositoryId: process.env.NEXT_PUBLIC_GISCUS_REPOSITORY_ID,
    //     category: process.env.NEXT_PUBLIC_GISCUS_CATEGORY,
    //     categoryId: process.env.NEXT_PUBLIC_GISCUS_CATEGORY_ID,
    //     mapping: "pathname", // supported options: pathname, url, title
    //     reactions: "1", // Emoji reactions: 1 = enable / 0 = disable
    //     // Send discussion metadata periodically to the parent window: 1 = enable / 0 = disable
    //     metadata: "0",
    //     // theme example: light, dark, dark_dimmed, dark_high_contrast
    //     // transparent_dark, preferred_color_scheme, custom
    //     theme: "light",
    //     // theme when dark mode
    //     darkTheme: "transparent_dark",
    //     // If the theme option above is set to 'custom`
    //     // please provide a link below to your custom theme css file.
    //     // example: https://giscus.app/themes/custom_example.css
    //     themeURL: "",
    //     // This corresponds to the `data-lang="en"` in giscus's configurations
    //     lang: "en",
    //   },
    // },
    // search: {
    //   provider: "kbar", // kbar or algolia
    //   kbarConfig: {
    //     searchDocumentsPath: "search.json", // path to load documents to search
    //   },
    // provider: 'algolia',
    // algoliaConfig: {
    //   // The application ID provided by Algolia
    //   appId: 'R2IYF7ETH7',
    //   // Public API key: it is safe to commit it
    //   apiKey: '599cec31baffa4868cae4e79f180729b',
    //   indexName: 'docsearch',
    // },
    // },
    newsletter: undefined,
    // newsletter: {
    //   // supports mailchimp, buttondown, convertkit, klaviyo, revue, emailoctopus
    //   // Please add your .env file and modify it according to your selection
    //   provider: "buttondown",
    // },
}

export default siteMetadata
