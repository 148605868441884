'use client'
import {
    Button,
    Link,
    Navbar,
    NavbarBrand,
    NavbarContent,
    NavbarItem,
    NavbarMenu,
    NavbarMenuItem,
    NavbarMenuToggle,
} from '@nextui-org/react'
import { useState } from 'react'
import SvgLayerSparkLogo from '@/components/icons_svg/LayerSparkLogo'
import siteMetadata, { MenuItem } from '@/data/siteMetadata'
import { usePathname } from 'next/navigation'

export default function SalesHeader() {
    const pathname = usePathname()
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    return (
        <header>
            <Navbar isBordered isMenuOpen={isMenuOpen} onMenuOpenChange={setIsMenuOpen}>
                <NavbarContent className="sm:hidden" justify="start">
                    <NavbarMenuToggle aria-label={isMenuOpen ? 'Close menu' : 'Open menu'} />
                </NavbarContent>

                <NavbarContent className="pr-3 sm:hidden" justify="center">
                    <NavbarBrand>
                        <Link href={'/'}>
                            <SvgLayerSparkLogo width={'50'} />
                            <p className="ml-3 text-2xl font-bold">
                                Layer<span className={'text-secondary'}>Spark</span>
                            </p>
                        </Link>
                    </NavbarBrand>
                </NavbarContent>

                <NavbarContent className="hidden gap-4 sm:flex" justify="center">
                    <NavbarBrand>
                        <Link href={'/'}>
                            <SvgLayerSparkLogo width={'50'} />
                            <p className="ml-3 mr-6 text-2xl font-bold md:mr-12">
                                Layer<span className={'text-secondary'}>Spark</span>
                            </p>
                        </Link>
                    </NavbarBrand>

                    {siteMetadata.salesMenuEnabled &&
                        siteMetadata.salesMenuItems.map((item: MenuItem) => {
                            return (
                                <NavbarItem
                                    key={`${item.to}-2`}
                                    isActive={pathname.startsWith(item.to)}
                                >
                                    <Link
                                        color="foreground"
                                        href={item.to}
                                        className={`${pathname.startsWith(item.to) && 'active'}`}
                                    >
                                        {item.label}
                                    </Link>
                                </NavbarItem>
                            )
                        })}
                </NavbarContent>

                <NavbarContent justify="end">
                    <NavbarItem className="hidden sm:flex lg:flex">
                        <Link href="/log-in">Login</Link>
                    </NavbarItem>
                    <NavbarItem>
                        <Button as={Link} color="secondary" href="/sign-up" variant="flat">
                            Sign Up
                        </Button>
                    </NavbarItem>
                </NavbarContent>

                <NavbarMenu>
                    {siteMetadata.salesMenuItems.map((item: MenuItem, index) => (
                        <NavbarMenuItem key={item.to} isActive={pathname.startsWith(item.to)}>
                            <Link
                                className={`w-full ${pathname.startsWith(item.to) && 'active'}`}
                                color={
                                    index === 2
                                        ? 'warning'
                                        : index === siteMetadata.salesMenuItems.length - 1
                                          ? 'danger'
                                          : 'foreground'
                                }
                                href={item.to}
                                size="lg"
                            >
                                {item.label}
                            </Link>
                        </NavbarMenuItem>
                    ))}
                    <NavbarItem className="mt-16">
                        <Button as={Link} color="primary" href="/log-in" variant="flat">
                            Log In
                        </Button>
                    </NavbarItem>
                </NavbarMenu>
            </Navbar>
        </header>
    )
}
